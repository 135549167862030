import { gql } from '@apollo/client'

import COMPANY_FRAGMENT from '../fragments/companyFields'

const mutation = gql`
  mutation(
    $id: Int!
    $name: String!
    $website: String
    $email: String
    $location: String
    $description: String
  ) {
    editCompany(
      company: {
        id: $id
        name: $name
        website: $website
        email: $email
        location: $location
        description: $description
      }
    ) {
      ...companyFields
    }
  }
  ${COMPANY_FRAGMENT}
`

export default mutation
