import { USER_STATUS } from 'constants/RemotelyDBConstants'

import { v4 as uuidv4 } from 'uuid'
import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import ApiService from 'services/Api'
import Auth0Service from 'services/Auth0'
import GraphQLService from 'services/GraphQL'
import { setLocalStorageItems, deleteLocalStorageItems } from 'helpers'

import {
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGN_UP_BY_INVITATION,
  SEND_INVITE,
  VALIDATE_INVITE_TICKET,
  RESET_USER_PASSWORD
} from '../constants/Auth'
import { dataFetched, userInvited } from '../actions/Data'
import {
  showAuthMessage,
  authenticated,
  // signOutSuccess,
  signUpSuccess,
  sendInviteSuccess,
  inviteTicketValid,
  inviteTicketInvalid,
  userPasswordResetSuccess
} from '../actions/Auth'

function* signIn() {
  yield takeEvery(SIGNIN, makeSignInRequest)
}

function* makeSignInRequest({ payload }) {
  const { email, password } = payload

  try {
    const { accessToken, userToken, userId, userSub, expiresAt } = yield call(
      Auth0Service.signIn,
      email,
      password
    )
    setLocalStorageItems({ accessToken, userToken, expiresAt, userId, userSub })

    yield put(authenticated(accessToken, userToken, expiresAt, +userId, userSub))
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* signUp() {
  yield takeEvery(SIGNUP, makeSignUpRequest)
}

function* makeSignUpRequest({ payload }) {
  const { firstName, lastName, companyName, email, title, password } = payload

  try {
    const companyId = yield call(GraphQLService.createCompany, companyName, email)
    const { userData, companyData, jobsData, frameworksData } = yield call(
      GraphQLService.createUser,
      companyId,
      firstName,
      lastName,
      email,
      title,
      USER_STATUS.VERIFIED.value
    )
    const { accessToken, userToken, userSub, expiresAt } = yield call(
      Auth0Service.signUp,
      email,
      password,
      userData.id.toString()
    )

    setLocalStorageItems({
      accessToken,
      userToken,
      expiresAt,
      userId: userData.id,
      userSub
    })

    yield put(signUpSuccess(accessToken, userToken, expiresAt, userData.id, userSub))
    yield put(dataFetched(userData, companyData, jobsData, frameworksData))
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* signUpByInvitation() {
  yield takeEvery(SIGN_UP_BY_INVITATION, makeSignUpByInvitationRequest)
}

function* makeSignUpByInvitationRequest({ userId, userSub, userData }) {
  const { firstName, lastName, email, password } = userData
  try {
    const token = yield call(ApiService.getManagementToken)
    yield call(Auth0Service.changePassword, token, password, userSub)
    yield call(Auth0Service.verifyEmail, token, userSub)
    yield call(GraphQLService.updateUser, userId, {
      firstName,
      lastName,
      status: USER_STATUS.VERIFIED.value
    })
    const { accessToken, userToken, expiresAt } = yield call(
      Auth0Service.signIn,
      email,
      password
    )

    setLocalStorageItems({ accessToken, userToken, expiresAt, userId, userSub })

    yield put(signUpSuccess(accessToken, userToken, expiresAt, userId, userSub))
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* signOut() {
  yield takeEvery(SIGNOUT, makeSignOutRequest)
}

function* makeSignOutRequest() {
  try {
    yield call(Auth0Service.signOut)
    deleteLocalStorageItems()
    // yield put(signOutSuccess()) Makes the page refresh after redirecting to /auth/login
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* sendInvite() {
  yield takeEvery(SEND_INVITE, makeSendInviteRequest)
}

function* makeSendInviteRequest({ company, email }) {
  try {
    const password = uuidv4() + 'Z' // Password needs to have 1 uppercase letter
    const { userData } = yield call(
      GraphQLService.createUser,
      company,
      '',
      '',
      email,
      '',
      USER_STATUS.PENDING.value
    )
    const { userSub } = yield call(
      Auth0Service.signUp,
      email,
      password,
      userData.id.toString()
    )
    yield call(ApiService.sendInvite, company, email, userData.id, userSub)
    yield put(sendInviteSuccess())
    yield put(
      userInvited({
        id: userData.id,
        email,
        status: USER_STATUS.PENDING.value
      })
    )
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* validateInviteTicket() {
  yield takeEvery(VALIDATE_INVITE_TICKET, makeValidateInviteTicketRequest)
}

function* makeValidateInviteTicketRequest({ ticketUrl }) {
  try {
    const isInviteTicketValid = yield call(ApiService.validateInviteTicket, ticketUrl)
    yield put(isInviteTicketValid ? inviteTicketValid() : inviteTicketInvalid())
  } catch (error) {
    console.error(error)
  }
}

function* resetUserPassword() {
  yield takeEvery(RESET_USER_PASSWORD, makeResetUserPasswordRequest)
}

function* makeResetUserPasswordRequest({ email }) {
  try {
    const response = yield call(ApiService.resetUserPassword, email)
    yield put(userPasswordResetSuccess())
    yield put(showAuthMessage(response))
  } catch (error) {
    yield put(showAuthMessage('Password reset failed. Try again.'))
  }
}

export {
  makeSignInRequest,
  makeSignUpRequest,
  makeSignUpByInvitationRequest,
  makeSignOutRequest,
  makeSendInviteRequest,
  makeValidateInviteTicketRequest,
  makeResetUserPasswordRequest
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(signUp),
    fork(signUpByInvitation),
    fork(signOut),
    fork(sendInvite),
    fork(validateInviteTicket),
    fork(resetUserPassword)
  ])
}
