import { SIDE_NAV_WIDTH } from 'constants/ThemeConstant'

import { Layout } from 'antd'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'

import MenuContent from './MenuContent'

const { Sider } = Layout

export const SideNav = ({
  navCollapsed,
  routeInfo,
  hideGroupTitle,
  localization = true
}) => {
  const props = { routeInfo, hideGroupTitle, localization }
  return (
    <Sider className="side-nav" width={SIDE_NAV_WIDTH} collapsed={navCollapsed}>
      <Scrollbars autoHide>
        <MenuContent {...props} />
      </Scrollbars>
    </Sider>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme
  return { navCollapsed }
}

export default connect(mapStateToProps)(SideNav)
