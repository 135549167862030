import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import ApiService from 'services/Api'
import Auth0Service from 'services/Auth0'
import GraphQLService from 'services/GraphQL'

import {
  FETCH_DATA,
  UPDATE_JOB,
  CREATE_JOB,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_COMPANY,
  EDIT_JOB_DELETE_STATUS
} from '../constants/Data'
import {
  showMessage,
  dataFetched,
  jobUpdated,
  jobCreated,
  userUpdated,
  userDeleted,
  companyUpdated
} from '../actions/Data'

function* fetchData() {
  yield takeEvery(FETCH_DATA, makeFetchDataRequest)
}

function* makeFetchDataRequest({ userId }) {
  try {
    const { userData, companyData, jobsData, frameworksData } = yield call(
      GraphQLService.getUser,
      +userId
    )
    yield put(dataFetched(userData, companyData, jobsData, frameworksData))
  } catch (error) {
    console.error(error)
  }
}

function* updateJob() {
  yield takeEvery(UPDATE_JOB, makeUpdateJobRequest)
}

function* makeUpdateJobRequest({ jobId, newData }) {
  try {
    const jobData = yield call(GraphQLService.updateJob, jobId, newData)
    yield put(jobUpdated(jobData))
  } catch (error) {
    console.error(error)
  }
}

function* createJob() {
  yield takeEvery(CREATE_JOB, makeCreateJobRequest)
}

function* makeCreateJobRequest({ jobData }) {
  try {
    const newJobData = yield call(GraphQLService.createJob, jobData)
    yield put(jobCreated(newJobData))
  } catch (error) {
    console.error(error)
  }
}

function* editDeleteStatus() {
  yield takeEvery(EDIT_JOB_DELETE_STATUS, makeEditDeleteStatusRequest)
}

function* makeEditDeleteStatusRequest({ jobId, isDeleted }) {
  try {
    const newJobData = yield call(GraphQLService.editDeleteStatus, jobId, isDeleted)

    yield put(jobUpdated(newJobData))
    yield put(showMessage('Job updated.'))
  } catch (error) {
    yield put(showMessage('Job update failed.'))
  }
}

function* updateUser() {
  yield takeEvery(UPDATE_USER, makeUpdateUserRequest)
}

function* makeUpdateUserRequest({ userId, newData, newEmail, newPassword }) {
  try {
    if (newPassword) {
      const token = yield call(ApiService.getManagementToken)
      yield call(Auth0Service.changePassword, token, newPassword)
    }
    if (newEmail) {
      const token = yield call(ApiService.getManagementToken)
      yield call(Auth0Service.changeEmail, token, newEmail)
    }
    const userData = yield call(GraphQLService.updateUser, userId, newData)
    yield put(userUpdated(userData))
  } catch (error) {
    yield put(showMessage(error))
  }
}

function* deleteUser() {
  yield takeEvery(DELETE_USER, makeDeleteUserRequest)
}

function* makeDeleteUserRequest({ userId, userEmail }) {
  try {
    yield call(GraphQLService.deleteUser, userId)
    yield call(ApiService.deleteUser, userEmail)
    yield put(userDeleted(userId))
  } catch (error) {
    console.error(error)
  }
}

function* updateCompany() {
  yield takeEvery(UPDATE_COMPANY, makeUpdateCompanyRequest)
}

function* makeUpdateCompanyRequest({ companyId, newData, newLogo }) {
  try {
    const companyData = yield call(GraphQLService.updateCompany, companyId, newData)
    if (newLogo) {
      companyData.logo = yield call(
        ApiService.updateCompanyLogo,
        companyId,
        companyData.name,
        newLogo
      )
    }
    yield put(companyUpdated(companyData))
  } catch (error) {
    console.error(error)
  }
}

export {
  makeFetchDataRequest,
  makeUpdateJobRequest,
  makeCreateJobRequest,
  makeEditDeleteStatusRequest,
  makeUpdateUserRequest,
  makeDeleteUserRequest,
  makeUpdateCompanyRequest
}

export default function* rootSaga() {
  yield all([
    fork(fetchData),
    fork(updateJob),
    fork(createJob),
    fork(editDeleteStatus),
    fork(updateUser),
    fork(deleteUser),
    fork(updateCompany)
  ])
}
