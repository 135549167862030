export class Framework {
  constructor({ id, name = '', websiteType = '' }) {
    this.id = id
    this.name = name
    this.websiteType = websiteType
  }
}

class Frameworks {
  constructor(frameworks = []) {
    this.frameworks = frameworks.map(framework => new Framework(framework))
  }

  /**
   * @return {Number[]} Array of all frameworks ids
   */
  getFrameworkIds() {
    return this.frameworks.map(framework => framework.id)
  }

  /**
   * @param {Number[]} ids - Array of framework ids whose names are needed
   * @return {Framework[]} Array of frameworks whose ids are included in `ids`
   */
  getFrameworkNamesByIds(ids) {
    return this.frameworks.reduce(
      (acc, framework) => (ids.includes(framework.id) ? [...acc, framework.name] : acc),
      []
    )
  }

  /**
   * @param {String[]} websiteTypes - Website types which the frameworks have to belong to
   * @return {Framework[]} Array of frameworks which belong to the given website types
   */
  getWebsiteFrameworks(websiteTypes) {
    return this.frameworks.filter(framework =>
      websiteTypes.includes(framework.websiteType)
    )
  }
}

export default Frameworks
