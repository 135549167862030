import { combineReducers } from 'redux'

import Auth from './Auth'
import Data from './Data'
import Theme from './Theme'

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  data: Data
})

export default reducers
