import { gql } from '@apollo/client'

const fragment = gql`
  fragment frameworkFields on Framework {
    id
    name
    websiteType
  }
`

export default fragment
