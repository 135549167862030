import { gql } from '@apollo/client'

const fragment = gql`
  fragment userFields on CompanyUser {
    id
    firstName
    lastName
    email
    title
    status
  }
`

export default fragment
