import {
  AUTH_TOKEN,
  USER_TOKEN,
  EXPIRES_AT,
  USER_ID,
  USER_SUB
} from 'constants/LocalStorageConstants'

import { deleteLocalStorageItems } from 'helpers'

import {
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SEND_INVITE_SUCCESS,
  INVITE_TICKET_VALID,
  INVITE_TICKET_INVALID,
  SHOW_LOADING,
  TOKEN_EXPIRED,
  RESET_USER_PASSWORD_SUCCESS
} from '../constants/Auth'

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  isInviteTicketValid: null,
  token: localStorage.getItem(AUTH_TOKEN),
  userToken: localStorage.getItem(USER_TOKEN),
  expiresAt: localStorage.getItem(EXPIRES_AT),
  userId: +localStorage.getItem(USER_ID),
  userSub: localStorage.getItem(USER_SUB)
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: '/',
        token: action.token,
        userToken: action.userToken,
        expiresAt: action.expiresAt,
        userId: action.userId,
        userSub: action.userSub
      }

    case TOKEN_EXPIRED:
      deleteLocalStorageItems()
      return {
        ...state,
        token: null,
        userToken: null,
        expiresAt: null,
        userId: null,
        userSub: null
      }

    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false
      }

    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false
      }

    case SIGNOUT_SUCCESS:
      return {
        ...state,
        redirect: '/',
        loading: false,
        token: null,
        userToken: null,
        expiresAt: null,
        userId: null,
        userSub: null
      }

    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        redirect: '/',
        token: action.token,
        userToken: action.userToken,
        expiresAt: action.expiresAt,
        userId: action.userId,
        userSub: action.userSub
      }

    case SEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case INVITE_TICKET_VALID:
      return {
        ...state,
        isInviteTicketValid: true,
        loading: false
      }

    case INVITE_TICKET_INVALID:
      return {
        ...state,
        isInviteTicketValid: false,
        loading: false
      }

    case SHOW_LOADING:
      return {
        ...state,
        loading: true
      }

    default:
      return state
  }
}

export default auth
