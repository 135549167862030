import { Link, withRouter } from 'react-router-dom'
import { Breadcrumb as BreadcrumbAnt } from 'antd'
import navigationConfig from 'configs/NavigationConfig'

const breadcrumbData = {
  '/app': 'Home'
}

navigationConfig.forEach((elm, i) => {
  const assignBreadcrumb = obj => (breadcrumbData[obj.path] = obj.text)
  assignBreadcrumb(elm)
  if (elm.submenu) {
    elm.submenu.forEach(elm => {
      assignBreadcrumb(elm)
      if (elm.submenu) {
        elm.submenu.forEach(elm => {
          assignBreadcrumb(elm)
        })
      }
    })
  }
})

export const Breadcrumb = ({ location }) => {
  const pathSnippets = location.pathname.split('/').filter(i => i)

  const buildBreadcrumb = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    return (
      <BreadcrumbAnt.Item key={url}>
        <Link to={url}>{breadcrumbData[url]}</Link>
      </BreadcrumbAnt.Item>
    )
  })

  return <BreadcrumbAnt>{buildBreadcrumb}</BreadcrumbAnt>
}

const BreadcrumbRoute = withRouter(Breadcrumb)

export const AppBreadcrumb = () => <BreadcrumbRoute />

export default AppBreadcrumb
