import { gql } from '@apollo/client'

import JOBS_FRAGMENT from '../fragments/jobFields'

const mutation = gql`
  mutation addJob(
    $companyId: Int!
    $title: String!
    $region: String
    $link: String!
    $description: String!
    $type: String
    $experience: String!
    $salary: Int
    $frameworks: [String]
    $premium: Boolean = true
    $websiteType: [String]!
  ) {
    addJob(
      job: {
        companyId: $companyId
        title: $title
        region: $region
        link: $link
        description: $description
        type: $type
        experience: $experience
        salary: $salary
        frameworks: $frameworks
        premium: $premium
        websiteType: $websiteType
      }
    ) {
      ...jobFields
    }
  }
  ${JOBS_FRAGMENT}
`

export default mutation
