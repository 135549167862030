export const NODE_ENV = process.env.NODE_ENV

export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
export const IMAGE_UPLOAD_URL = process.env.REACT_APP_IMAGE_UPLOAD_URL
export const SEND_INVITE_URL = process.env.REACT_APP_SEND_INVITE_URL
export const VALIDATE_TICKET_URL = process.env.REACT_APP_VALIDATE_TICKET_URL
export const MANAGEMENT_TOKEN_URL = process.env.REACT_APP_MANAGEMENT_TOKEN_URL
export const DELETE_USER_URL = process.env.REACT_APP_DELETE_USER_URL
export const RESET_USER_PASSWORD_URL = process.env.REACT_APP_RESET_USER_PASSWORD_URL

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_DATABASE_NAME = process.env.REACT_APP_AUTH0_DATABASE_NAME
export const AUTH0_USER_ID_NAMESPACE = process.env.REACT_APP_AUTH0_USER_ID_NAMESPACE

export const PADDLE_VENDOR = +process.env.REACT_APP_PADDLE_VENDOR
export const PADDLE_PRODUCT = +process.env.REACT_APP_PADDLE_PRODUCT
