import utils from 'utils'

export class Visit {
  constructor({ id, location = '', createdAt = '' }) {
    this.id = id
    this.location = location
    this.createdAt = createdAt
  }
}

class Visits {
  constructor(visits = []) {
    this.visits = visits.map(visit => new Visit(visit))
  }

  /**
   * @return {Visit[]} Array of all available visits
   */
  getAllVisits() {
    return this.visits
  }

  /**
   * Gets all visits which were created in the specified month
   * @param {Number} n - How many months should the target month be before todays month. Default is 0.
   * @return {Visit[]} Array of visits
   */
  getNthMonthsVisits(n = 0) {
    return utils.getNthMonthsValues(this.visits, 'createdAt', n)
  }

  /**
   * Gets all visits which were created in the last `n` days ordered by days
   * @param {Number} n - How many days in the past should the function count occurrences for
   * @return {Visit[][]} Array of arrays which contain visits if any of them were created in day corresponding to that index
   */
  getVisitsInLastNDays(n) {
    return utils.getValuesInLastNDays(this.visits, 'createdAt', n)
  }

  getVisitsInRangeDays(start, end) {
    return utils.getValuesInRangeDays(this.visits, 'createdAt', start, end)
  }
}

export default Visits
