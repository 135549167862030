import {
  DASHBOARD_PREFIX_PATH,
  HOME_PAGE_PATH,
  JOBS_PAGE_PATH,
  SETTINGS_PREFIX_PATH,
  USER_SETTINGS_PAGE_PATH,
  COMPANY_SETTINGS_PAGE_PATH,
  TEAM_SETTINGS_PAGE_PATH
} from 'constants/NavigationConstants'

import {
  DashboardOutlined,
  ProfileOutlined,
  SettingOutlined,
  UserOutlined,
  TeamOutlined
} from '@ant-design/icons'

const dashboardNavTree = [
  {
    key: 'dashboard',
    path: '',
    title: 'sidenav.dashboard',
    text: 'Dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboard-home',
        path: DASHBOARD_PREFIX_PATH + HOME_PAGE_PATH,
        title: 'sidenav.dashboard.home',
        text: 'Home',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dashboard-jobList',
        path: DASHBOARD_PREFIX_PATH + JOBS_PAGE_PATH,
        title: 'sidenav.dashboard.jobList',
        text: 'Job List',
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  }
]

const settingsTree = [
  {
    key: 'settings',
    path: '',
    title: 'sidenav.settings',
    text: 'Settings',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'settings-company',
        path: SETTINGS_PREFIX_PATH + COMPANY_SETTINGS_PAGE_PATH,
        title: 'sidenav.settings.company',
        text: 'Company',
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'settings-profile',
        path: SETTINGS_PREFIX_PATH + USER_SETTINGS_PAGE_PATH,
        title: 'sidenav.settings.profile',
        text: 'Profile',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'settings-team',
        path: SETTINGS_PREFIX_PATH + TEAM_SETTINGS_PAGE_PATH,
        title: 'sidenav.settings.team',
        text: 'Team',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  }
]

const navigationConfig = [...dashboardNavTree, ...settingsTree]

export default navigationConfig
