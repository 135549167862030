// Authentication paths
export const AUTH_PREFIX_PATH = '/auth'
export const LOGIN_PAGE_PATH = '/login'
export const REGISTER_PAGE_PATH = '/register'
export const FORGOT_PASSWORD_PAGE_PATH = '/forgot-password'
export const ERROR_PAGE_ONE_PATH = '/error-1'
export const ERROR_PAGE_TWO_PATH = '/error-2'

// Dashboard paths
export const DASHBOARD_PREFIX_PATH = '/dashboard'
export const HOME_PAGE_PATH = '/home'
export const JOBS_PAGE_PATH = '/jobs'
export const JOB_PAGE_PATH = '/job'
export const EDIT_JOB_PATH = '/edit-job'
export const ADD_JOB_PATH = '/add-job'

// Settings paths
export const SETTINGS_PREFIX_PATH = '/settings'
export const USER_SETTINGS_PAGE_PATH = '/user'
export const COMPANY_SETTINGS_PAGE_PATH = '/company'
export const TEAM_SETTINGS_PAGE_PATH = '/team'
