class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    const initials = name.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  /**
   * Filters out object in an array whose `key` value doesn't match the specified month
   * @param {Object[]} array - An array containing objects which have the `key` property
   * @param {String} key - Objects property which contains a valid date string used for comparison
   * @param {Number} n - How many months should the target month be before todays month. Default is 0.
   * @return {Array} Array of objects whose `key` matches the specified month
   */
  static getNthMonthsValues(array, key, n = 0) {
    const targetDate = new Date()
    targetDate.setMonth(targetDate.getMonth() - n)
    const targetYear = targetDate.getFullYear()
    const targetMonth = targetDate.getMonth()

    return array.filter(val => {
      const keysDate = new Date(val[key])
      const keysYear = keysDate.getFullYear()
      const keysMonth = keysDate.getMonth()
      return keysYear === targetYear && keysMonth === targetMonth
    })
  }

  /**
   * Counts occurrences for the last `n` days in the past in `array`
   * @param {Object[]} array - An array containing objects which have the `key` property
   * @param {String} key - Objects property which contains a valid date string used for comparison
   * @param {Number} n - How many days in the past should the function count occurrences for
   * @return {Array} Array of arrays which contain objects whose `key` matched with a date that happened on the corresponding index
   */
  static getValuesInLastNDays(array, key, n) {
    const lastNDays = []
    const date = new Date()
    for (let i = 0; i < n; i++) {
      lastNDays.unshift(date.toDateString())
      date.setDate(date.getDate() - 1)
    }

    return lastNDays.map(day => {
      const occurrences = array.reduce((acc, val) => {
        const keyDate = new Date(val[key])
        return day === keyDate.toDateString() ? [...acc, val] : acc
      }, [])
      return occurrences
    })
  }

  static getValuesInRangeDays(array, key, start, end) {
    let rangeDays = []
    const date = new Date(start)

    const days = (end.getTime() - start.getTime()) / (1000 * 3600 * 24) + 1

    for (let i = 0; i < days; i++) {
      rangeDays = [...rangeDays, date.toDateString()]

      date.setDate(date.getDate() + 1)
    }

    return rangeDays.map(day => {
      const occurrences = array.reduce((acc, val) => {
        const keyDate = new Date(val[key])
        return day === keyDate.toDateString() ? [...acc, val] : acc
      }, [])
      return occurrences
    })
  }

  /**
   * Gets last months name
   * @return {String} Name of the last month
   */
  static getLastMonthName() {
    const date = new Date()
    date.setDate(0)
    return date.toLocaleString('default', { month: 'long' })
  }

  /**
   * Creates an array of the last `n` dates in this format: `1 Jan`
   * @param {Number} n - How many days in the past should the array contain
   * @return {String[]} Array of the last `n` days
   */
  static getLastNDays(n) {
    const date = new Date()
    const dates = []
    for (let i = 0; i < n; i++) {
      const day = date.getDate()
      const month = date.toLocaleString('default', { month: 'short' })
      dates.unshift(`${day} ${month}`)
      date.setDate(date.getDate() - 1)
    }
    return dates
  }

  static getDatesInRange(selectedDate) {
    const dates = []
    const selDate = {
      startDate: new Date(selectedDate.startDate),
      endDate: new Date(selectedDate.endDate)
    }
    const currentDate = selDate.endDate

    let DAYS =
      (selDate.endDate.getTime() - selDate.startDate.getTime()) / (1000 * 3600 * 24) + 1

    DAYS = Math.round(DAYS)

    for (let i = 0; i < DAYS; i++) {
      const day = currentDate.getDate()
      const month = currentDate.toLocaleString('default', { month: 'short' })

      dates.unshift(`${day} ${month}`)

      currentDate.setDate(currentDate.getDate() - 1)
    }

    return dates
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree
    }

    let route
    for (const p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
        route = this.getRouteInfo(navTree[p], path)
        if (route) {
          return route
        }
      }
    }

    return route
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16)
    let G = parseInt(color.substring(3, 5), 16)
    let B = parseInt(color.substring(5, 7), 16)
    R = parseInt((R * (100 + percent)) / 100)
    G = parseInt((G * (100 + percent)) / 100)
    B = parseInt((B * (100 + percent)) / 100)
    R = R < 255 ? R : 255
    G = G < 255 ? G : 255
    B = B < 255 ? B : 255
    const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
    const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
    const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)
    return `#${RR}${GG}${BB}`
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive
    }
    if (number < 0) {
      return negative
    }
    return null
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key]
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      a = a[key].toLowerCase()
      b = b[key].toLowerCase()
      return a > b ? 1 : b > a ? -1 : 0
    }

    if (typeof a[key] === 'boolean' && typeof b[key] === 'boolean') {
      return a ? -1 : 1
    }

    if (Array.isArray(a[key]) && Array.isArray(b[key])) {
      a = a[key][0].toLowerCase()
      b = b[key][0].toLowerCase()
      return a > b ? 1 : b > a ? -1 : 0
    }
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list
    if (list) {
      data = list.filter(item => item[key] !== value)
    }
    return data
  }

  /**
   * @param {String} key - object key which determines which objects to keep
   * @param {Array} lists - any number of arrays of objects which have the `key` property
   * @return {Array} Array which has values that are found in all of the given lists
   */
  static getCommonArrayValues(key, ...lists) {
    const listCount = lists.length
    const countedKeys = []
    const data = lists.flat().filter((value, _, arr) => {
      if (countedKeys.includes(value[key])) {
        return false
      }
      countedKeys.push(value[key])
      const occurrencesCount = arr.reduce(
        (sum, obj) => (obj[key] === value[key] ? sum + 1 : sum),
        0
      )
      if (occurrencesCount === listCount) {
        return true
      }
      return false
    })
    return data
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    const breakpoints = []
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key]
        if (element) {
          breakpoints.push(key)
        }
      }
    }
    return breakpoints
  }
}

export default Utils
