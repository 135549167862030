import {
  SHOW_DATA_LOADING,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  FETCH_DATA,
  DATA_FETCHED,
  UPDATE_JOB,
  JOB_UPDATED,
  CREATE_JOB,
  JOB_CREATED,
  JOB_PAID,
  UPDATE_USER,
  USER_UPDATED,
  DELETE_USER,
  USER_DELETED,
  USER_INVITED,
  UPDATE_COMPANY,
  COMPANY_UPDATED,
  SELECTED_DATE,
  SET_XAXIS,
  EDIT_JOB_DELETE_STATUS
} from '../constants/Data'

export const showLoading = () => ({
  type: SHOW_DATA_LOADING
})

export const showMessage = message => ({
  type: SHOW_MESSAGE,
  message
})

export const hideMessage = () => ({
  type: HIDE_MESSAGE
})

export const fetchData = userId => ({
  type: FETCH_DATA,
  userId
})

export const dataFetched = (userData, companyData, jobsData, frameworksData) => ({
  type: DATA_FETCHED,
  userData,
  companyData,
  jobsData,
  frameworksData
})

export const updateJob = (jobId, newData) => ({
  type: UPDATE_JOB,
  jobId,
  newData
})

export const updateJobDeleteStatus = (jobId, isDeleted) => ({
  type: EDIT_JOB_DELETE_STATUS,
  jobId,
  isDeleted
})

export const jobUpdated = jobData => ({
  type: JOB_UPDATED,
  jobData
})

export const createJob = jobData => ({
  type: CREATE_JOB,
  jobData
})

export const jobCreated = jobData => ({
  type: JOB_CREATED,
  jobData
})

export const jobPaid = jobId => ({
  type: JOB_PAID,
  jobId
})

export const updateUser = (userId, newData, newEmail, newPassword) => ({
  type: UPDATE_USER,
  userId,
  newData,
  newEmail,
  newPassword
})

export const userUpdated = userData => ({
  type: USER_UPDATED,
  userData
})

export const deleteUser = (userId, userEmail) => ({
  type: DELETE_USER,
  userId,
  userEmail
})

export const userDeleted = userId => ({
  type: USER_DELETED,
  userId
})

export const userInvited = userData => ({
  type: USER_INVITED,
  userData
})

export const updateCompany = (companyId, newData, newLogo) => ({
  type: UPDATE_COMPANY,
  companyId,
  newData,
  newLogo
})

export const companyUpdated = companyData => ({
  type: COMPANY_UPDATED,
  companyData
})

export const setSelectedDate = (date, categories) => ({
  type: SELECTED_DATE,
  date: date,
  categories: categories
})

export const setXAxisCategories = categories => ({
  type: SET_XAXIS,
  xAxisCategories: categories
})
