import User from './User'

class Company {
  constructor(companyData) {
    this.initCompany(companyData)
  }

  initCompany({
    id,
    name = '',
    logo = '',
    website = '',
    description = '',
    email = '',
    location = '',
    users = []
  }) {
    this.id = id
    this.name = name
    this.logo = logo
    this.website = website
    this.description = description
    this.email = email
    this.location = location
    this.users = users.map(user => new User(user))
  }

  updateCompany(companyData) {
    this.initCompany(companyData)
  }

  addUser(userData) {
    this.users.push(new User(userData))
  }

  deleteUser(userId) {
    this.users = this.users.filter(user => user.id !== userId)
  }
}

export default Company
