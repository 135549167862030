import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Grid } from 'antd'
import { connect } from 'react-redux'
import { onMobileNavToggle } from 'redux/actions/Theme'
import utils from 'utils'
import navigationConfig from 'configs/NavigationConfig'

import Icon from '../util-components/Icon'
import NavigationMenuItem from '../util-components/NavigationMenuItem'

const { SubMenu } = Menu
const { useBreakpoint } = Grid

const setDefaultOpen = key => {
  const keyList = []
  let keyString = ''
  if (key) {
    const arr = key.split('-')
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index]
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`)
      keyList.push(keyString)
    }
  }
  return keyList
}

const SideNavContent = ({ routeInfo, hideGroupTitle, onMobileNavToggle }) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }

  return (
    <Menu
      theme="light"
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {navigationConfig.map(menu =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup key={menu.key} title={menu.text}>
            {menu.submenu.map(subMenuFirst =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  key={subMenuFirst.key}
                  title={subMenuFirst.text}
                >
                  {subMenuFirst.submenu.map(subMenuSecond => (
                    <NavigationMenuItem path={subMenuSecond.path} key={subMenuSecond.key}>
                      {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                      <span>{subMenuSecond.text}</span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </NavigationMenuItem>
                  ))}
                </SubMenu>
              ) : (
                <NavigationMenuItem
                  path={subMenuFirst.path}
                  key={subMenuFirst.key}
                  data-cy={subMenuFirst.key}
                >
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{subMenuFirst.text}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </NavigationMenuItem>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <NavigationMenuItem path={menu.path} key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{menu?.text}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </NavigationMenuItem>
        )
      )}
    </Menu>
  )
}

const MenuContent = props => {
  return <SideNavContent {...props} />
}

const mapDispatchToProps = { onMobileNavToggle }

export default connect(null, mapDispatchToProps)(MenuContent)
