import {
  AUTH_PREFIX_PATH,
  LOGIN_PAGE_PATH,
  REGISTER_PAGE_PATH,
  FORGOT_PASSWORD_PAGE_PATH,
  ERROR_PAGE_ONE_PATH,
  ERROR_PAGE_TWO_PATH
} from 'constants/NavigationConstants'

import { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'

const AuthViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={AUTH_PREFIX_PATH + LOGIN_PAGE_PATH}
          component={lazy(() => import(`./pages/login`))}
        />
        <Route
          path={AUTH_PREFIX_PATH + REGISTER_PAGE_PATH}
          component={lazy(() => import(`./pages/register`))}
        />
        <Route
          path={AUTH_PREFIX_PATH + FORGOT_PASSWORD_PAGE_PATH}
          component={lazy(() => import(`./pages/forgot-password`))}
        />
        <Route
          path={AUTH_PREFIX_PATH + ERROR_PAGE_ONE_PATH}
          component={lazy(() => import(`./pages/error-page-1`))}
        />
        <Route
          path={AUTH_PREFIX_PATH + ERROR_PAGE_TWO_PATH}
          component={lazy(() => import(`./pages/error-page-2`))}
        />
        <Redirect from={AUTH_PREFIX_PATH} to={AUTH_PREFIX_PATH + LOGIN_PAGE_PATH} />
      </Switch>
    </Suspense>
  )
}

export default AuthViews
