import { gql } from '@apollo/client'

import COMPANY_FRAGMENT from '../fragments/companyFields'
import FRAMEWORKS_FRAGMENT from '../fragments/frameworkFields'

const query = gql`
  query($name: String!) {
    getCompanyByName(name: $name) {
      ...companyFields
    }

    listFrameworks {
      ...frameworkFields
    }
  }
  ${COMPANY_FRAGMENT}
  ${FRAMEWORKS_FRAGMENT}
`

export default query
