import React from 'react'
import { CloseOutlined } from '@ant-design/icons'

import SearchInput from './SearchInput'

export const NavSearch = ({ active, close }) => {
  return (
    <div className={`nav-search ${active ? 'nav-search-active' : ''}`}>
      <div className="d-flex align-items-center w-100">
        <SearchInput close={close} active={active} />
      </div>
      <div className="nav-close" onClick={close}>
        <CloseOutlined />
      </div>
    </div>
  )
}

export default NavSearch
