export const TYPES = [
  { label: 'Part time', value: 'Part time' },
  { label: 'Full time', value: 'Full time' },
  { label: 'Intership', value: 'Intership' }
]

export const EXPERIENCE = [
  { label: 'None', value: 'None' },
  { label: 'Junior', value: 'Junior' },
  { label: 'Medior', value: 'Medior' },
  { label: 'Senior', value: 'Senior' }
]

export const SALARIES = [
  {
    label: 'Not specified',
    value: null
  },
  {
    label: 'Less than 50.000$/annum',
    value: 49999
  },
  {
    label: '50.000$ - 100.000$/annum',
    value: 50001
  },
  {
    label: 'More than 100.000$/annum',
    value: 100001
  }
]

export const USER_STATUS = {
  VERIFIED: {
    label: 'Verified',
    value: 'verified'
  },
  PENDING: {
    label: 'Pending',
    value: 'pending'
  }
}
