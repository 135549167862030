import {
  TOGGLE_COLLAPSED_NAV,
  CHANGE_LOCALE,
  TOGGLE_MOBILE_NAV
} from '../constants/Theme'

export const toggleCollapsedNav = navCollapsed => ({
  type: TOGGLE_COLLAPSED_NAV,
  navCollapsed
})

export const onLocaleChange = locale => ({
  type: CHANGE_LOCALE,
  locale
})

export const onMobileNavToggle = mobileNav => ({
  type: TOGGLE_MOBILE_NAV,
  mobileNav
})
