import { gql } from '@apollo/client'

const mutation = gql`
  mutation($name: String!, $email: String!) {
    addCompany(company: { name: $name, email: $email }) {
      id
    }
  }
`

export default mutation
