import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH } from 'constants/ThemeConstant'

import React, { memo } from 'react'
import { Layout, Grid } from 'antd'
import { connect } from 'react-redux'
import SideNav from 'components/layout-components/SideNav'
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav'
import PageHeader from 'components/layout-components/PageHeader'
import Footer from 'components/layout-components/Footer'
import AppViews from 'views/app-views'
import navigationConfig from 'configs/NavigationConfig'
import utils from 'utils'

const { Content } = Layout
const { useBreakpoint } = Grid

export const AppLayout = ({ navCollapsed, location }) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint())

  const isMobile = !screens.includes('lg')

  const getLayoutGutter = () => {
    if (isMobile) {
      return 0
    }

    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Layout className="app-container">
        {!isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
          <div className={'app-content'}>
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.text}
            />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, locale } = theme
  return { navCollapsed, locale }
}

export default connect(mapStateToProps)(memo(AppLayout))
