import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/userFields'

const mutation = gql`
  mutation(
    $id: Int!
    $firstName: String
    $lastName: String
    $title: String
    $email: String
    $status: String
  ) {
    editCompanyUser(
      user: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        title: $title
        email: $email
        status: $status
      }
    ) {
      ...userFields
    }
  }
  ${USER_FRAGMENT}
`

export default mutation
