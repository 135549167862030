import {
  DASHBOARD_PREFIX_PATH,
  SETTINGS_PREFIX_PATH
} from 'constants/NavigationConstants'

import { memo, lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'

const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={DASHBOARD_PREFIX_PATH}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route path={SETTINGS_PREFIX_PATH} component={lazy(() => import(`./settings`))} />
        <Redirect from="/" to={DASHBOARD_PREFIX_PATH} />
      </Switch>
    </Suspense>
  )
}

export default memo(AppViews)
