export const COMPANY_NAME_TAKEN =
  'A company {companyName} has already been registered. The owner will need to invite you to register. Feel free to contact us for more information.'
export const INVALID_COMPANY_NAME = "Company doesn't exist."
export const INVALID_EMAIL_MESSAGE = 'Invalid email address.'
export const INVALID_SIGNUP_MESSAGE = 'Email already in use.'
export const INVALID_PASSWORD_MESSAGE = 'Password too weak.'
export const AUTHENTICATION_FAILED_MESSAGE = 'Authentication failed. Please try again.'

export const INVALID_SIGNUP_CODE = 'invalid_signup'
export const INVALID_PASSWORD_CODE = 'invalid_password'
