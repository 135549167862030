import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/userFields'
import COMPANY_FRAGMENT from '../fragments/companyFields'
import JOBS_FRAGMENT from '../fragments/jobFields'

const mutation = gql`
  mutation(
    $companyId: Int!
    $firstName: String
    $lastName: String
    $email: String!
    $title: String
    $status: String!
  ) {
    addCompanyUser(
      user: {
        companyId: $companyId
        firstName: $firstName
        lastName: $lastName
        email: $email
        title: $title
        status: $status
      }
    ) {
      ...userFields
      company {
        ...companyFields
        jobs {
          ...jobFields
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${JOBS_FRAGMENT}
`

export default mutation
