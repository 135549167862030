import {
  IMAGE_UPLOAD_URL,
  SEND_INVITE_URL,
  VALIDATE_TICKET_URL,
  MANAGEMENT_TOKEN_URL,
  DELETE_USER_URL,
  RESET_USER_PASSWORD_URL
} from 'configs/EnvironmentConfig'

class ApiService {
  static async updateCompanyLogo(companyId, companyName, newLogo) {
    const formData = new FormData()
    formData.append('companyId', companyId)
    formData.append('companyName', companyName)
    formData.append('image', newLogo)

    const response = await fetch(IMAGE_UPLOAD_URL, { method: 'POST', body: formData })
    const data = await response.json()

    return data.imageUrl
  }

  static async sendInvite(company, email, userId, userSub) {
    await fetch(SEND_INVITE_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ company, email, userId, userSub })
    })
  }

  static async validateInviteTicket(ticketUrl) {
    const response = await fetch(
      VALIDATE_TICKET_URL + '?' + new URLSearchParams({ ticketUrl }),
      { method: 'GET' }
    )
    const data = await response.json()

    return data.isTicketValid
  }

  static async resetUserPassword(userEmail) {
    const response = await fetch(RESET_USER_PASSWORD_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: userEmail })
    })
    const data = await response.json()

    return data.message
  }

  static async getManagementToken() {
    const response = await fetch(MANAGEMENT_TOKEN_URL, { method: 'GET' })
    const data = await response.json()

    return data.token
  }

  static async deleteUser(userEmail) {
    await fetch(DELETE_USER_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userEmail })
    })
  }
}

export default ApiService
