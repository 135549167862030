import { Drawer } from 'antd'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { onMobileNavToggle } from 'redux/actions/Theme'
import Flex from 'components/shared-components/Flex'

import MenuContent from './MenuContent'
import Logo from './Logo'

export const MobileNav = ({
  mobileNav,
  onMobileNavToggle,
  routeInfo,
  hideGroupTitle,
  localization = true
}) => {
  const props = { routeInfo, hideGroupTitle, localization }

  const onClose = () => {
    onMobileNavToggle(false)
  }

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo={true} />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, mobileNav } = theme
  return { navCollapsed, mobileNav }
}

const mapDispatchToProps = { onMobileNavToggle }

export default connect(mapStateToProps, mapDispatchToProps)(MobileNav)
