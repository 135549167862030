import { Menu } from 'antd'
import { useHistory } from 'react-router-dom'

const { Item } = Menu

const NavigationMenuItem = ({ path, className, children, ...rest }) => {
  const history = useHistory()
  const location = history.location.pathname

  return (
    <Item
      className={`${className || ''} ${location !== path ? 'unselected' : ''}`}
      {...rest}
    >
      {children}
    </Item>
  )
}

export default NavigationMenuItem
