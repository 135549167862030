import {
  SETTINGS_PREFIX_PATH,
  USER_SETTINGS_PAGE_PATH,
  COMPANY_SETTINGS_PAGE_PATH,
  TEAM_SETTINGS_PAGE_PATH
} from 'constants/NavigationConstants'

import { Menu, Dropdown, Avatar } from 'antd'
import { connect } from 'react-redux'
import {
  UserOutlined,
  SettingOutlined,
  TeamOutlined,
  LogoutOutlined
} from '@ant-design/icons'
import { signOut } from 'redux/actions/Auth'
import { Link } from 'react-router-dom'
import Icon from 'components/util-components/Icon'

import utils from '../../utils/index'
import NavigationMenuItem from '../util-components/NavigationMenuItem'

const menuItems = [
  {
    title: 'Company Settings',
    icon: SettingOutlined,
    path: SETTINGS_PREFIX_PATH + COMPANY_SETTINGS_PAGE_PATH
  },
  {
    title: 'Profile Settings',
    icon: UserOutlined,
    path: SETTINGS_PREFIX_PATH + USER_SETTINGS_PAGE_PATH
  },
  {
    title: 'Team Settings',
    icon: TeamOutlined,
    path: SETTINGS_PREFIX_PATH + TEAM_SETTINGS_PAGE_PATH
  }
]

export const NavProfile = ({
  signOut,
  userFullName,
  userTitle,
  companyName,
  companyLogo
}) => {
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div style={{ width: 40 }}>
            <Avatar src={companyLogo}>{utils.getNameInitial(companyName)}</Avatar>
          </div>
          <div className="pl-2">
            <h4 className="mb-0" data-cy="header-profile-menu-user-fname">
              {userFullName}
            </h4>
            <span className="text-muted" data-cy="header-profile-menu-user-title">
              {userTitle && `${userTitle} at `}
              {companyName}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItems.map((el, i) => {
            return (
              <NavigationMenuItem path={el.path} key={i}>
                <Link to={el.path} className="w-100 c-link">
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </NavigationMenuItem>
            )
          })}
          <Menu.Item
            data-cy="header-profile-menu-logout"
            key={menuItems.legth + 1}
            onClick={() => signOut()}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-items-center" mode="horizontal">
        <Menu.Item className="d-flex align-items-center" data-cy="header-profile-menu">
          <Avatar style={{ backgroundColor: 'gray' }}>
            {utils.getNameInitial(userFullName)}
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

const mapStateToProps = ({ data }) => {
  const { user, company } = data
  return {
    userFullName: user.fullName,
    userTitle: user.title,
    companyName: company.name,
    companyLogo: company.logo
  }
}

const mapDispatchToProps = { signOut }

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile)
