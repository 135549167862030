import {
  AUTH_TOKEN,
  USER_TOKEN,
  EXPIRES_AT,
  USER_ID,
  USER_SUB
} from 'constants/LocalStorageConstants'

export const setLocalStorageItems = ({
  accessToken,
  userToken,
  expiresAt,
  userId,
  userSub
}) => {
  localStorage.setItem(AUTH_TOKEN, accessToken)
  localStorage.setItem(USER_TOKEN, userToken)
  localStorage.setItem(EXPIRES_AT, expiresAt)
  localStorage.setItem(USER_ID, userId)
  localStorage.setItem(USER_SUB, userSub)
}

export const deleteLocalStorageItems = () => {
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(USER_TOKEN)
  localStorage.removeItem(EXPIRES_AT)
  localStorage.removeItem(USER_ID)
  localStorage.removeItem(USER_SUB)
}
