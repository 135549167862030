import { THEME_CONFIG } from 'configs/AppConfig'

import {
  TOGGLE_COLLAPSED_NAV,
  CHANGE_LOCALE,
  TOGGLE_MOBILE_NAV
} from '../constants/Theme'

const initTheme = { ...THEME_CONFIG }

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      }

    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale
      }

    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav
      }

    default:
      return state
  }
}

export default theme
