import Visits from './Visits'
import Clicks from './Clicks'
import Frameworks from './Frameworks'

export class Job {
  constructor(jobData) {
    this.initJob(jobData)
  }

  initJob({
    id,
    title = '',
    websiteType = [],
    region = '',
    link = '',
    description = '',
    type = '',
    experience = '',
    salary = null,
    createdAt = '',
    deletedAt = '',
    payment = false,
    visits = [],
    clicks = [],
    frameworks = []
  }) {
    this.id = id
    this.title = title
    this.websiteType = websiteType
    this.region = region
    this.link = link
    this.description = description
    this.type = type
    this.experience = experience
    this.salary = salary
    this.isPaid = !!payment
    this.createdAt = new Date(createdAt)
    this.deletedAt = deletedAt ? new Date(deletedAt) : null
    this.visits = new Visits(visits)
    this.clicks = new Clicks(clicks)
    this.frameworks = new Frameworks(frameworks)
  }

  updateJob(jobData) {
    this.initJob(jobData)
  }

  setPaymentStatus(isPaid) {
    this.isPaid = isPaid
  }

  /**
   * Data used in the monthly statistics section on the home page
   * @return {Object} Object containing this months visit and click count and their comparison to the last months count
   */
  getMonthlyStatistics() {
    const thisMonthVisitCount = this.visits.getNthMonthsVisits().length
    const lastMonthVisitCount = this.visits.getNthMonthsVisits(1).length

    const thisMonthClickCount = this.clicks.getNthMonthsClicks().length
    const lastMonthClickCount = this.clicks.getNthMonthsClicks(1).length

    return {
      visits: {
        count: thisMonthVisitCount,
        lastMonthComparison: thisMonthVisitCount - lastMonthVisitCount
      },
      clicks: {
        count: thisMonthClickCount,
        lastMonthComparison: thisMonthClickCount - lastMonthClickCount
      }
    }
  }

  /**
   * Data used in the statistics chart on the home page and job page
   * @param {Number} n - How many days in the past should the function count occurrences for
   * @return { {visits: Array<number>, clicks: Array<number>}} An object that contains visits and clicks count for the last `n` days
   */
  getChartStatistics(n) {
    const visitsInLastNDays = this.visits
      .getVisitsInLastNDays(n)
      .map(visitArray => visitArray.length)

    const clicksInLastNDays = this.clicks
      .getClicksInLastNDays(n)
      .map(visitArray => visitArray.length)

    return {
      visits: visitsInLastNDays,
      clicks: clicksInLastNDays
    }
  }

  getChartStatisticsForRange(start, end) {
    const visitsInRangeDays = this.visits
      .getVisitsInRangeDays(start, end)
      .map(visitArray => visitArray.length)

    const clicksInLastRangeDays = this.clicks
      .getClicksInRangeDays(start, end)
      .map(visitArray => visitArray.length)

    return {
      visits: visitsInRangeDays,
      clicks: clicksInLastRangeDays
    }
  }

  /**
   * Data used in the regional statistics chart on the job page
   * @return {Object[]} Array of objects which contain name of a region and how many visits happened from that region
   */
  getRegionalStatistics() {
    const visits = this.visits.getAllVisits().reduce(
      (acc, visit) => ({
        ...acc,
        [visit.location]: visit.location in acc ? acc[visit.location] + 1 : 1
      }),
      {}
    )

    return Object.keys(visits).map(region => ({
      name: region,
      value: visits[region]
    }))
  }

  /**
   * Data used in the regional statistics chart in specific range on the job page
   * @return {Object[]} Array of objects which contain name of a region and how many visits happened from that region
   */
  getRegionalStatisticsForRange(start, end) {
    const visitsInRangeByDay = this.visits.getVisitsInRangeDays(start, end)

    const allVisitsFromRange = visitsInRangeByDay.reduce(
      (acc, day) => [...acc, ...day],
      []
    )

    const visits = allVisitsFromRange.reduce((acc, visit) => {
      return {
        ...acc,
        [visit.location]: visit.location in acc ? acc[visit.location] + 1 : 1
      }
    }, {})

    return Object.keys(visits).map(region => ({
      name: region,
      value: visits[region]
    }))
  }
}

class Jobs {
  constructor(jobs = []) {
    this.jobs = jobs.map(job => new Job(job))
  }

  /**
   * Creates a new `Job` instance in the `jobs` array
   * @param {...Job} jobData - Data with which to create the new `Job` instance
   */
  addJob(jobData) {
    this.jobs.unshift(new Job(jobData))
  }

  /**
   * @param {Number} id - Id of the job to look for
   * @return {Job} Job with the specified id
   */
  getJob(id) {
    return this.jobs.find(job => job.id === id)
  }

  /**
   * @return {Job} Job with the latest `createdAt` property
   */
  getLatestCreatedJob() {
    return this.jobs.reduce(
      (acc, job) => (job.createdAt.getTime() > acc.createdAt.getTime() ? job : acc),
      this.jobs[0]
    )
  }

  /**
   * @return {Job[]} Array of all available jobs
   */
  getAllJobs() {
    return this.jobs
  }

  /**
   * @return {Job[]} Array of all paid jobs
   */
  getPaidJobs() {
    return this.jobs.filter(job => job.isPaid)
  }

  /**
   * Data used in the monthly statistics section on the home page
   * @return {Object} Object containing this months visit and click count and their comparison to the last months count
   */
  getMonthlyStatistics() {
    return this.jobs.reduce(
      (acc, job) => {
        const { visits, clicks } = job.getMonthlyStatistics()
        return {
          visits: {
            count: acc.visits.count + visits.count,
            lastMonthComparison:
              acc.visits.lastMonthComparison + visits.lastMonthComparison
          },
          clicks: {
            count: acc.clicks.count + clicks.count,
            lastMonthComparison:
              acc.clicks.lastMonthComparison + clicks.lastMonthComparison
          }
        }
      },
      {
        visits: {
          count: 0,
          lastMonthComparison: 0
        },
        clicks: {
          count: 0,
          lastMonthComparison: 0
        }
      }
    )
  }

  /**
   * Data used in the statistics chart on the home page and job page
   * @param {Number} n - How many days in the past should the function count occurrences for
   * @return { {visits: Array<number>, clicks: Array<number>}} An object that contains combined visits and clicks count from all jobs for the last `n` days
   */
  getChartStatistics(n) {
    const stats = this.jobs.reduce(
      (acc, job) => {
        const { visits, clicks } = job.getChartStatistics(n)

        return {
          visits: [...acc.visits, visits],
          clicks: [...acc.clicks, clicks]
        }
      },
      {
        visits: [],
        clicks: []
      }
    )

    return {
      visits: stats.visits.reduce(
        (acc, visits) => acc.map((visitSum, i) => visitSum + visits[i]),
        new Array(n).fill(0)
      ),
      clicks: stats.clicks.reduce(
        (acc, clicks) => acc.map((clickSum, i) => clickSum + clicks[i]),
        new Array(n).fill(0)
      )
    }
  }

  getChartStatisticsForRange(start, end) {
    const stats = this.jobs.reduce(
      (acc, job) => {
        const { visits, clicks } = job.getChartStatisticsForRange(start, end)
        return {
          visits: [...acc.visits, visits],
          clicks: [...acc.clicks, clicks]
        }
      },
      {
        visits: [],
        clicks: []
      }
    )

    let DAYS = (end.getTime() - start.getTime()) / (1000 * 3600 * 24) + 1

    DAYS = Math.round(DAYS)

    return {
      visits: stats.visits.reduce(
        (acc, visits) => acc.map((visitSum, i) => visitSum + visits[i]),
        new Array(DAYS).fill(0)
      ),
      clicks: stats.clicks.reduce(
        (acc, clicks) => acc.map((clickSum, i) => clickSum + clicks[i]),
        new Array(DAYS).fill(0)
      )
    }
  }

  /**
   * Performs a search of all of jobs' titles
   * @param {Number | String} input - search value
   * @return {Job[]} Array of jobs which contain `input` in their `title`
   */
  wildcardSearch(input) {
    return this.jobs.filter(
      job => job.title.toUpperCase().indexOf(input.toString().toUpperCase()) !== -1
    )
  }

  /**
   * Filter out jobs which have no intersecting values in `websiteType` with `types`
   * @param {String[]} types - website types by which to filter
   * @return {Job[]} Array or jobs which belong to the given website types. Returns all jobs if no types were provided.
   */
  filterJobsByTypes(types) {
    if (types.length === 0) {
      return this.jobs
    }
    return this.jobs.filter(job => !!job.websiteType.find(type => !!types.includes(type)))
  }

  /**
   * Filter out jobs which don't match with the given payment status
   * @param {boolean} paymentStatus  - determines what payment status to filter by
   * @return {Job[]} Array of jobs which have the given payment status
   */
  filterJobsByPaymentStatus(paymentStatus) {
    return this.jobs.filter(job => job.isPaid === paymentStatus)
  }
}

export default Jobs
