import { connect } from 'react-redux'
import { fetchData } from 'redux/actions/Data'
import React, { useEffect } from 'react'
import Loading from 'components/shared-components/Loading'

import Layout from './Layout'

const App = ({ userId, isDataFetched, fetchData, location }) => {
  useEffect(() => {
    if (!isDataFetched) {
      fetchData(userId)
    }
  }, [])

  return isDataFetched ? <Layout location={location} /> : <Loading cover="content" />
}

const mapStateToProps = ({ auth, data }) => {
  const { userId } = auth
  const { isDataFetched } = data
  return { userId, isDataFetched }
}

const mapDispatchToProps = { fetchData }

export default connect(mapStateToProps, mapDispatchToProps)(App)
