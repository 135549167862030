import {
  AUTH_PREFIX_PATH,
  DASHBOARD_PREFIX_PATH,
  SETTINGS_PREFIX_PATH
} from 'constants/NavigationConstants'
import { TICKET_URL_PARAM } from 'constants/InvitationQueryParams'

import { useEffect } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ConfigProvider } from 'antd'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import { showLoading, tokenExpired } from 'redux/actions/Auth'

const RouteInterceptor = ({ children, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

const Views = ({ locale, isAuthenticated, hasTokenExpired, tokenExpired, location }) => {
  useEffect(() => {
    if (hasTokenExpired) {
      tokenExpired()
    }
  }, [])

  return (
    <ConfigProvider>
      <Switch>
        <Route exact path="/">
          <Redirect to={DASHBOARD_PREFIX_PATH} />
        </Route>
        <Route path={AUTH_PREFIX_PATH}>
          <AuthLayout />
        </Route>
        <RouteInterceptor
          path={[DASHBOARD_PREFIX_PATH, SETTINGS_PREFIX_PATH]}
          isAuthenticated={isAuthenticated}
        >
          <AppLayout location={location} />
        </RouteInterceptor>
      </Switch>
    </ConfigProvider>
  )
}

const mapStateToProps = ({ theme, auth }, { location }) => {
  const { locale } = theme
  const { token, expiresAt } = auth

  const isInvited = new URLSearchParams(location.search).get(TICKET_URL_PARAM)

  return {
    locale,
    isAuthenticated: isInvited ? false : !!token,
    hasTokenExpired: isInvited ? true : new Date().getTime() > expiresAt
  }
}

const mapDispatchToProps = { showLoading, tokenExpired }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views))
