import { gql } from '@apollo/client'

import JOBS_FRAGMENT from '../fragments/jobFields'

const mutation = gql`
  mutation($id: Int!, $isDeleted: Boolean = true) {
    editDeleteStatus(id: $id, isDeleted: $isDeleted) {
      ...jobFields
    }
  }
  ${JOBS_FRAGMENT}
`

export default mutation
