import {
  INVALID_SIGNUP_CODE,
  INVALID_PASSWORD_CODE,
  INVALID_EMAIL_MESSAGE,
  INVALID_SIGNUP_MESSAGE,
  INVALID_PASSWORD_MESSAGE,
  AUTHENTICATION_FAILED_MESSAGE
} from 'constants/ErrorConstants'
import { USER_SUB } from 'constants/LocalStorageConstants'

import {
  AUTH0_DOMAIN,
  AUTH0_DATABASE_NAME,
  AUTH0_USER_ID_NAMESPACE
} from 'configs/EnvironmentConfig'
import auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'

import { auth } from './auth-client'

class Auth0Service {
  static signIn(email, password) {
    return new Promise((resolve, reject) => {
      auth.client.login(
        {
          username: email,
          password: password,
          realm: AUTH0_DATABASE_NAME
        },
        (error, authResult) => {
          if (error) {
            return reject(error.description)
          }
          if (!authResult?.accessToken) {
            return reject(AUTHENTICATION_FAILED_MESSAGE)
          }
          const userInfo = jwtDecode(authResult.idToken)

          const expiresAt = `${authResult.expiresIn * 1000 + new Date().getTime()}`

          return resolve({
            accessToken: authResult.accessToken,
            userToken: authResult.idToken,
            userSub: userInfo.sub,
            userId: userInfo[AUTH0_USER_ID_NAMESPACE],
            expiresAt
          })
        }
      )
    })
  }

  static signUp(email, password, userId) {
    return new Promise((resolve, reject) => {
      auth.signupAndAuthorize(
        {
          email: email,
          password: password,
          connection: AUTH0_DATABASE_NAME,
          userMetadata: { userId }
        },
        (error, authResult) => {
          if (error) {
            if (error.code === INVALID_SIGNUP_CODE) {
              return reject(INVALID_SIGNUP_MESSAGE)
            }

            if (error.code === INVALID_PASSWORD_CODE) {
              return reject(INVALID_PASSWORD_MESSAGE)
            }

            return reject(AUTHENTICATION_FAILED_MESSAGE)
          }

          if (!authResult?.accessToken) {
            return reject(AUTHENTICATION_FAILED_MESSAGE)
          }

          const userInfo = jwtDecode(authResult.idToken)

          const expiresAt = `${authResult.expiresIn * 1000 + new Date().getTime()}`

          return resolve({
            accessToken: authResult.accessToken,
            userToken: authResult.idToken,
            userSub: userInfo.sub,
            expiresAt
          })
        }
      )
    })
  }

  static signOut() {
    auth.logout({
      returnTo: window.location.origin
    })
  }

  static changePassword(token, newPassword, userSub) {
    const sub = userSub || localStorage.getItem(USER_SUB)
    const authManagement = new auth0.Management({ domain: AUTH0_DOMAIN, token })

    return new Promise((resolve, reject) => {
      authManagement.patchUserAttributes(
        sub,
        {
          connection: AUTH0_DATABASE_NAME,
          password: newPassword
        },
        error => (error ? reject(INVALID_PASSWORD_MESSAGE) : resolve())
      )
    })
  }

  static changeEmail(token, newEmail) {
    const sub = localStorage.getItem(USER_SUB)
    const authManagement = new auth0.Management({ domain: AUTH0_DOMAIN, token })

    return new Promise((resolve, reject) => {
      authManagement.patchUserAttributes(
        sub,
        {
          connection: AUTH0_DATABASE_NAME,
          email: newEmail
        },
        error => (error ? reject(INVALID_EMAIL_MESSAGE) : resolve())
      )
    })
  }

  static verifyEmail(token, userSub) {
    const sub = userSub
    const authManagement = new auth0.Management({ domain: AUTH0_DOMAIN, token })

    return new Promise((resolve, reject) => {
      authManagement.patchUserAttributes(
        sub,
        {
          connection: AUTH0_DATABASE_NAME,
          email_verified: true
        },
        error => (error ? reject(AUTHENTICATION_FAILED_MESSAGE) : resolve())
      )
    })
  }
}

export default Auth0Service
