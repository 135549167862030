import { gql } from '@apollo/client'

import JOBS_FRAGMENT from '../fragments/jobFields'

const mutation = gql`
  mutation(
    $id: Int!
    $companyId: Int
    $title: String
    $description: String
    $link: String
    $premium: Boolean
    $email: String
    $salary: Int
    $experience: String
    $type: String
    $websiteType: [String]
    $region: String
    $frameworks: [Int]
  ) {
    editJob(
      job: {
        id: $id
        companyId: $companyId
        title: $title
        description: $description
        link: $link
        premium: $premium
        email: $email
        salary: $salary
        experience: $experience
        type: $type
        websiteType: $websiteType
        region: $region
        frameworks: $frameworks
      }
    ) {
      ...jobFields
    }
  }
  ${JOBS_FRAGMENT}
`

export default mutation
