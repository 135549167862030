import { gql } from '@apollo/client'

import FRAMEWORKS_FRAGMENT from '../fragments/frameworkFields'

const query = gql`
  query {
    listFrameworks {
      ...frameworkFields
    }
  }
  ${FRAMEWORKS_FRAGMENT}
`

export default query
