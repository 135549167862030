import { gql } from '@apollo/client'

import USERS_FRAGMENT from '../fragments/userFields'

const fragment = gql`
  fragment companyFields on Company {
    id
    name
    logo
    website
    description
    email
    location
    users {
      ...userFields
    }
  }
  ${USERS_FRAGMENT}
`

export default fragment
