import { gql } from '@apollo/client'

const mutation = gql`
  mutation($id: Int!) {
    deleteCompanyUser(id: $id) {
      id
    }
  }
`

export default mutation
