import { gql } from '@apollo/client'

import FRAMEWORKS_FRAGMENT from '../fragments/frameworkFields'

const fragment = gql`
  fragment jobFields on Job {
    id
    title
    websiteType
    region
    link
    description
    type
    experience
    salary
    createdAt
    deletedAt
    frameworks {
      ...frameworkFields
    }
    payment {
      id
    }
    clicks {
      id
      createdAt
    }
    visits {
      id
      location
      createdAt
    }
  }
  ${FRAMEWORKS_FRAGMENT}
`

export default fragment
