class User {
  constructor(userData) {
    this.initUser(userData)
  }

  initUser({ id, firstName = '', lastName = '', email = '', title = '', status = '' }) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.title = title
    this.status = status
  }

  updateUser(userData) {
    this.initUser(userData)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}

export default User
