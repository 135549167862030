import utils from 'utils'

export class Click {
  constructor({ id, createdAt = '' }) {
    this.id = id
    this.createdAt = createdAt
  }
}

class Clicks {
  constructor(clicks = []) {
    this.clicks = clicks.map(click => new Click(click))
  }

  /**
   * @return {Click[]} Array of all available clicks
   */
  getAllClicks() {
    return this.clicks
  }

  /**
   * Gets all clicks which were created in the specified month
   * @param {Number} n - How many months should the target month be before todays month. Default is 0.
   * @return {Click[]} Array of clicks
   */
  getNthMonthsClicks(n = 0) {
    return utils.getNthMonthsValues(this.clicks, 'createdAt', n)
  }

  /**
   * Gets all clicks which were created in the last `n` days ordered by days
   * @param {Number} n - How many days in the past should the function count occurrences for
   * @return {Click[][]} Array of arrays which contain clicks if any of them were created in day corresponding to that index
   */
  getClicksInLastNDays(n) {
    return utils.getValuesInLastNDays(this.clicks, 'createdAt', n)
  }

  getClicksInRangeDays(start, end) {
    return utils.getValuesInRangeDays(this.clicks, 'createdAt', start, end)
  }
}

export default Clicks
