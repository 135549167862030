export const SHOW_DATA_LOADING = 'SHOW_DATA_LOADING'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const FETCH_DATA = 'FETCH_DATA'
export const DATA_FETCHED = 'DATA_FETCHED'
export const UPDATE_JOB = 'UPDATE_JOB'
export const JOB_UPDATED = 'JOB_UPDATED'
export const EDIT_JOB_DELETE_STATUS = 'EDIT_JOB_DELETE_STATUS'
export const CREATE_JOB = 'CREATE_JOB'
export const JOB_CREATED = 'JOB_CREATED'
export const JOB_PAID = 'JOB_PAID'
export const UPDATE_USER = 'UPDATE_USER'
export const USER_UPDATED = 'USER_UPDATED'
export const DELETE_USER = 'DELETE_USER'
export const USER_DELETED = 'USER_DELETED'
export const USER_INVITED = 'USER_INVITED'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const COMPANY_UPDATED = 'COMPANY_UPDATED'
export const SELECTED_DATE = 'SELECTED_DATE'
export const SET_XAXIS = 'SET_XAXIS'
