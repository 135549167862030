export const SIGNIN = 'SIGNIN'
export const AUTHENTICATED = 'AUTHENTICATED'
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
export const SIGNOUT = 'SIGNOUT'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP'
export const SIGN_UP_BY_INVITATION = 'SIGN_UP_BY_INVITATION'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SEND_INVITE = 'SEND_INVITE'
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS'
export const VALIDATE_INVITE_TICKET = 'VALIDATE_INVITE_TICKET'
export const INVITE_TICKET_VALID = 'INVITE_TICKET_VALID'
export const INVITE_TICKET_INVALID = 'INVITE_TICKET_INVALID'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
