import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/userFields'
import COMPANY_FRAGMENT from '../fragments/companyFields'
import JOBS_FRAGMENT from '../fragments/jobFields'
import FRAMEWORKS_FRAGMENT from '../fragments/frameworkFields'

const query = gql`
  query($id: Int!) {
    getCompanyUser(id: $id) {
      ...userFields
      company {
        ...companyFields
        jobs(includeDeleted: true) {
          ...jobFields
        }
      }
    }

    listFrameworks {
      ...frameworkFields
    }
  }
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${JOBS_FRAGMENT}
  ${FRAMEWORKS_FRAGMENT}
`

export default query
