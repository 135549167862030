import { INVALID_COMPANY_NAME, COMPANY_NAME_TAKEN } from 'constants/ErrorConstants'

import GET_USER_AND_COMPANY from './queries/getUserAndCompany'
import GET_COMPANY_BY_NAME from './queries/getCompanyByName'
import LIST_FRAMEWORKS from './queries/listFrameworks'
import DELETE_COMPANY_USER from './mutations/deleteCompanyUser'
import EDIT_COMPANY_USER from './mutations/editCompanyUser'
import ADD_COMPANY_USER from './mutations/addCompanyUser'
import EDIT_COMPANY from './mutations/editCompany'
import ADD_COMPANY from './mutations/addCompany'
import EDIT_JOB from './mutations/editJob'
import ADD_JOB from './mutations/addJob'
import EDIT_JOB_DELETE_STATUS from './mutations/editJobDeleteStatus'
import { client } from './client'

class GraphQLService {
  static async getUser(userId) {
    const { data } = await client.query({
      query: GET_USER_AND_COMPANY,
      variables: { id: userId }
    })

    const userData = data.getCompanyUser
    const companyData = data.getCompanyUser.company
    const jobsData = data.getCompanyUser.company.jobs
    const frameworksData = data.listFrameworks

    return { userData, companyData, jobsData, frameworksData }
  }

  static async createCompany(companyName, email) {
    const { data: getCompanyData } = await client.query({
      query: GET_COMPANY_BY_NAME,
      variables: { name: companyName }
    })

    if (getCompanyData.getCompanyByName) {
      if (
        !('users' in getCompanyData.getCompanyByName) ||
        getCompanyData.getCompanyByName.users.length === 0
      ) {
        return getCompanyData.getCompanyByName.id
      } else throw COMPANY_NAME_TAKEN.replace('{companyName}', companyName)
    }

    const { data: addCompanyData } = await client.mutate({
      mutation: ADD_COMPANY,
      variables: { name: companyName, email }
    })

    const companyId = addCompanyData.addCompany.id

    return companyId
  }

  static async createUser(company, firstName, lastName, email, title, status) {
    let companyId, frameworksData

    if (typeof company === 'number') {
      const { data: getFrameworksData } = await client.query({
        query: LIST_FRAMEWORKS
      })

      companyId = company
      frameworksData = getFrameworksData.listFrameworks
    }

    if (typeof company === 'string') {
      const { data: getCompanyData } = await client.query({
        query: GET_COMPANY_BY_NAME,
        variables: { name: company }
      })

      if (!getCompanyData.getCompanyByName) {
        throw INVALID_COMPANY_NAME
      }

      companyId = getCompanyData.getCompanyByName.id
      frameworksData = getCompanyData.listFrameworks
    }

    const { data: addUserData } = await client.mutate({
      mutation: ADD_COMPANY_USER,
      variables: { companyId, firstName, lastName, email, title, status }
    })

    const userData = addUserData.addCompanyUser
    const companyData = addUserData.addCompanyUser.company
    const jobsData = addUserData.addCompanyUser.jobs

    return { userData, companyData, jobsData, frameworksData }
  }

  static async updateJob(jobId, newData) {
    const { data } = await client.mutate({
      mutation: EDIT_JOB,
      variables: { id: jobId, ...newData }
    })

    const jobData = data.editJob

    return jobData
  }

  static async editDeleteStatus(jobId, isDeleted) {
    const { data } = await client.mutate({
      mutation: EDIT_JOB_DELETE_STATUS,
      variables: { id: jobId, isDeleted }
    })

    const jobData = data.editDeleteStatus

    return jobData
  }

  static async createJob(newJobData) {
    const { data } = await client.mutate({
      mutation: ADD_JOB,
      variables: { ...newJobData }
    })

    const jobData = data.addJob

    return jobData
  }

  static async updateUser(userId, newData) {
    const { data } = await client.mutate({
      mutation: EDIT_COMPANY_USER,
      variables: { id: userId, ...newData }
    })

    const userData = data.editCompanyUser

    return userData
  }

  static async deleteUser(userId) {
    await client.mutate({
      mutation: DELETE_COMPANY_USER,
      variables: { id: userId }
    })
  }

  static async updateCompany(companyId, newData) {
    const { data } = await client.mutate({
      mutation: EDIT_COMPANY,
      variables: { id: companyId, ...newData }
    })

    const companyData = data.editCompany

    return companyData
  }
}

export default GraphQLService
