import {
  SIGNIN,
  AUTHENTICATED,
  TOKEN_EXPIRED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGN_UP_BY_INVITATION,
  SIGNUP_SUCCESS,
  SEND_INVITE,
  SEND_INVITE_SUCCESS,
  VALIDATE_INVITE_TICKET,
  INVITE_TICKET_VALID,
  INVITE_TICKET_INVALID,
  SHOW_LOADING,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS
} from '../constants/Auth'

export const signIn = user => ({
  type: SIGNIN,
  payload: user
})

export const authenticated = (token, userToken, expiresAt, userId, userSub) => ({
  type: AUTHENTICATED,
  token,
  userToken,
  expiresAt,
  userId,
  userSub
})

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
})

export const signOut = () => ({
  type: SIGNOUT
})

export const signOutSuccess = () => ({
  type: SIGNOUT_SUCCESS
})

export const signUp = user => ({
  type: SIGNUP,
  payload: user
})

export const signUpByInvitation = (userId, userSub, userData) => ({
  type: SIGN_UP_BY_INVITATION,
  userId,
  userSub,
  userData
})

export const signUpSuccess = (token, userToken, expiresAt, userId, userSub) => ({
  type: SIGNUP_SUCCESS,
  token,
  userToken,
  expiresAt,
  userId,
  userSub
})

export const sendInvite = (company, email) => ({
  type: SEND_INVITE,
  company,
  email
})

export const sendInviteSuccess = () => ({
  type: SEND_INVITE_SUCCESS
})

export const validateInviteTicket = ticketUrl => ({
  type: VALIDATE_INVITE_TICKET,
  ticketUrl
})

export const inviteTicketValid = () => ({
  type: INVITE_TICKET_VALID
})

export const inviteTicketInvalid = () => ({
  type: INVITE_TICKET_INVALID
})

export const showAuthMessage = message => ({
  type: SHOW_AUTH_MESSAGE,
  message
})

export const hideAuthMessage = () => ({
  type: HIDE_AUTH_MESSAGE
})

export const showLoading = () => ({
  type: SHOW_LOADING
})

export const resetUserPassword = email => ({
  type: RESET_USER_PASSWORD,
  email
})

export const userPasswordResetSuccess = () => ({
  type: RESET_USER_PASSWORD_SUCCESS
})
