import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant'

import { useState } from 'react'
import { connect } from 'react-redux'
import { Menu, Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme'

import Logo from './Logo'
import NavProfile from './NavProfile'
import NavSearch from './NavSearch'

const { Header } = Layout

export const HeaderNav = ({
  navCollapsed,
  mobileNav,
  toggleCollapsedNav,
  onMobileNavToggle,
  isMobile
}) => {
  const [searchActive, setSearchActive] = useState(false)

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const getNavWidth = () => {
    if (isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  return (
    <Header className="app-header">
      <div className="app-header-wrapper">
        <Logo />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <Menu mode="horizontal">
              <Menu.Item key="0" onClick={() => onToggle()}>
                {navCollapsed || isMobile ? (
                  <MenuUnfoldOutlined className="nav-icon" />
                ) : (
                  <MenuFoldOutlined className="nav-icon" />
                )}
              </Menu.Item>
            </Menu>
          </div>
          <div className="nav-right">
            <NavProfile />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, mobileNav } = theme
  return { navCollapsed, mobileNav }
}

const mapDispatchToProps = { toggleCollapsedNav, onMobileNavToggle }

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav)
