import User from 'interfaces/User'
import Company from 'interfaces/Company'
import Jobs from 'interfaces/Jobs'
import Frameworks from 'interfaces/Frameworks'

import {
  SHOW_DATA_LOADING,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  DATA_FETCHED,
  JOB_UPDATED,
  JOB_CREATED,
  JOB_PAID,
  USER_UPDATED,
  USER_INVITED,
  USER_DELETED,
  COMPANY_UPDATED,
  SELECTED_DATE,
  SET_XAXIS
} from '../constants/Data'

const currentDate = new Date()
const initialStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
const initialEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

const initState = {
  user: {},
  company: {},
  jobs: {},
  frameworks: {},
  loading: false,
  message: '',
  showMessage: false,
  isDataFetched: false,
  selectedDate: {
    startDate: initialStartDate,
    endDate: initialEndDate,
    key: 'selection'
  },
  xAxisCategories: []
}

const data = (state = initState, action) => {
  switch (action.type) {
    case SHOW_DATA_LOADING:
      return { ...state, loading: true }

    case SHOW_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false
      }

    case HIDE_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false
      }

    case DATA_FETCHED:
      return {
        ...state,
        user: new User(action.userData),
        company: new Company(action.companyData),
        jobs: new Jobs(action.jobsData),
        frameworks: new Frameworks(action.frameworksData),
        isDataFetched: true
      }

    case JOB_UPDATED:
      state.jobs.getJob(action.jobData.id).updateJob(action.jobData)
      return { ...state, loading: false }

    case JOB_CREATED:
      state.jobs.addJob(action.jobData)
      return { ...state, loading: false }

    case JOB_PAID:
      state.jobs.getJob(action.jobId).setPaymentStatus(true)
      return { ...state }

    case USER_UPDATED:
      state.user.updateUser(action.userData)
      return { ...state, loading: false }

    case USER_INVITED:
      state.company.addUser(action.userData)
      return { ...state }

    case USER_DELETED:
      state.company.deleteUser(action.userId)
      return { ...state, loading: false }

    case COMPANY_UPDATED:
      state.company.updateCompany(action.companyData)
      return { ...state, loading: false }

    case SELECTED_DATE: {
      const date = {
        startDate: action.date.startDate,
        endDate: action.date.endDate,
        key: action.date.key
      }
      const categories = action.categories

      return {
        ...state,
        selectedDate: date,
        xAxisCategories: categories
      }
    }

    case SET_XAXIS:
      return {
        ...state,
        xAxisCategories: action.xAxisCategories
      }

    default:
      return state
  }
}

export default data
